<template>
  <div>
    <div>
      <img class="top_image" :src="menu.bannerImg" alt=""/>
    </div>
    <!-- 面包屑 -->
    <div class="mbx">
      <div style="width: 1200px; margin: 20px auto">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 16px">
            <router-link to="/">首页</router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 16px">
            <router-link :to="{ path: '/news', query: { id: breadid } }">新闻资讯</router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 16px">
            新闻正文
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <p class="small_line"></p>
    </div>
    <div class="fgx">
      <p class="title"><span>新闻资讯</span></p>
      <div class="line">
        <p class="linebg"></p>
      </div>
    </div>

    <div class="detal">
      <div style="text-align: left">
        <h1 style="text-align: center">{{ title }}</h1>
        <!-- <h3 style="margin-top:10px;text-align:center">{{subtitle}}</h3> -->
        <p style="
              margin-top: 30px;
              color: #ccc;
              margin-bottom: 30px;
              text-align: center;
            ">
          文章录入时间:&nbsp;&nbsp;
          <span style="color: #444">{{ time }}</span>
          &nbsp;&nbsp;&nbsp;&nbsp;文章来源：<span style="color: #444">{{
            laiyuan
          }}</span
        >&nbsp;&nbsp;&nbsp;&nbsp;浏览次数：<span style="color: #444">{{
            liulancount ? liulancount : 0
          }}</span>
        </p>
      </div>
      <p v-html="detal" class="ql-editor contentdel" style="
            text-align: left;
            border-top: 1px solid #efefef;
            padding-top: 20px;
            padding-bottom: 20px;
          "></p>
    </div>
    <div class="middle">
      <p class="middle_title">
        <img src="../../static/切图文件/ICON-left.png" alt=""/>
        <span class="name">新闻资讯</span>
        <img src="../../static/切图文件/ICON-right.png" alt=""/>
      </p>
      <div class="newscontent">
        <div
            class="news_content"
            v-for="(item, index) in newlist"
            :key="index"
            @click="news(item.id)"
            style="cursor: pointer"
        >
          <div class="mdimgcontent">
            <img style="width: 358px; height: 178px" :src="item.bannerImg" alt=""/>
            <p class="dw">
                <span class="ywx_content_line1" style="padding-left: 12px">{{
                    item.title
                  }}</span>
            </p>
          </div>

          <p style="
                margin-top: 12px;
                padding-left: 12px;
                color: #000000;
                font-size: 12px;
                text-align: left;
              " class="bews_line">
            {{ item.subtitle }}
          </p>
        </div>
        <el-button round class="downbtn" @click="addcount">更多资讯</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getContentInfo,
  getContentList,
  getMenuInfo
} from "../request/index";
// import $ from "jquery";

export default {
  data() {
    return {
      detal: "",
      title: "",
      time: "",
      subtitle: "",
      newlist: [],
      liulancount: "",
      newsid: "",
      bread: "",
      breadid: "",
      laiyuan: "",
      menu: {
        bannerImg: ""
      }
    };
  },
  created() {
    getContentInfo(this.$route.query.newsId).then(res => {
      this.detal = res.data.content;
      this.title = res.data.title;
      this.time = this.ChangeDateFormat(res.data.createTime);
      this.subtitle = res.data.subtitle;
      this.liulancount = res.data.hits;
      this.laiyuan = res.data.source;
      getMenuInfo(res.data.menuId).then(res1 => {
        this.menu = res1.data;
      });
    });
    let params = {
      pageNum: 1,
      pageSize: 3,
      type: 4
    };
    getContentList(params).then(res => {
      this.newlist = res.rows;
    });
  },
  methods: {
    //时间转换
    ChangeDateFormat(val) {
      if (val != null) {
        var datetime = new Date(val);
        //月份为0-11，所以+1，月份小于10时补个0

        var year = datetime.getFullYear();
        var month = datetime.getMonth() + 1; //js从0开始取
        var date = datetime.getDate();

        if (month < 10) {
          month = "0" + month;
        }
        if (date < 10) {
          date = "0" + date;
        }
        var time = year + "年" + month + "月" + date + "日"; //2015-11-4 14:11:41

        return time;
      }
      return "";
    },
    // 加载更多
    addcount() {
      this.$router.push({
        path: "/news",
        query: {id: this.menu.id}
      });
    },
    //获取新闻详情
    news(key) {
      if(this.$route.query.newsId!=key){
        this.$router.push({
          path: "/news/detail/",
          query: {
            newsId: key
          }
        });
      }
    }
  },
  mounted() {
  },
  computed: {}
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  text-align: center;
}

.small_line {
  background: #ddd;
  height: 1px;
}

.ywx_content_line1 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.bews_line {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.top_image {
  width: 100%;
}

.fgx {
  width: 1200px;
  margin: 0 auto;
  margin-top: 30px;
}

.fgx .title {
  font-weight: 900;
  text-align: left;
  margin-bottom: 10px;
}

.fgx .line {
  width: 100%;
  height: 5px;
  background-color: #eeeeee;
}

.line .linebg {
  height: 5px;
  width: 64px;
  background-color: black;
}

.http {
  width: 700px;
  margin: 0 auto;
  border: 1px solid #dddddd;
  height: 30px;
  margin-bottom: 100px;
  line-height: 30px;
}

.bigline {
  height: 1px;
  width: 1200px;
  margin: 10px auto;
  background-color: #dddddd;
}

.middle .newscontent {
  margin: 0 auto;
  width: 1200px;
  margin-top: 20px;
  vertical-align: top;
  text-align: left;
  margin-bottom: 60px;
  padding-bottom: 30px;
  position: relative;
}

.middle .newscontent .news_content {
  display: inline-block;
  width: 358px;
  height: 240px;
  margin-left: 20px;
  margin-right: 20px;
  border: 1px solid #dddddd;
  margin-top: 20px;
  vertical-align: top;
}

.middle .newscontent .news_content .mdimgcontent {
  width: 358px;
  height: 180px;
  position: relative;
}

.middle .newscontent .news_content .dw {
  margin: 0;
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 99;
  text-align: left;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

.detal {
  width: 1000px;
  margin: 0 auto;
  margin-bottom: 40px;
  padding-top: 40px;
  border-bottom: 1px solid #ddd;
}

.middle .middle_title {
  margin-top: 50px;
  font-size: 30px;
  font-weight: 700;
  color: rgb(249, 145, 0);
}

.downbtn {
  position: absolute;
  height: 40px;
  left: 50%;
  transform: translate(-52px);
  bottom: -40px;
}

.jiazaimes {
  font-size: 18px;
  color: rgb(249, 145, 0);
}

.middle .newscontent .news_content:hover {
  box-shadow: 0 0 20px #aaa;
}
</style>
